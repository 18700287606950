import {
  GraphQLExceptionCode,
  graphQLErrorHandler,
} from '@features/core/graphql/graphql-errors'
import {
  ValidationFieldsProps,
  ValidationInputs,
  validateEmail,
} from '@common/utils/use-form-validation'

import { AuthIdentityProviderCodeEnum } from '@generated/types.d'
import { ApolloError } from '@apollo/client'

const unauthorizedHandler = ({ intl, setError, setLoginLoading }) => {
  const message = intl.formatMessage(
    {
      id: 'ACCOUNT_DISABLED',
      defaultMessage: 'This account is no longer active. Contact {link}.',
    },
    {
      link: (
        <a
          style={{ textDecoration: 'underline' }}
          href={`mailto:${intl.formatMessage({
            id: 'SUPPORT_EMAIL',
            defaultMessage: 'support@palqee.com',
          })}`}
        >
          {intl.formatMessage({ id: 'SUPPORT', defaultMessage: 'Support' })}
        </a>
      ),
    },
  )

  setError('password', {
    type: GraphQLExceptionCode.NotAuthorizedException,
    message,
  })
  setLoginLoading(false)
}

const wrongCredentialsHandler = ({ intl, setError, setLoginLoading }) => {
  setError('password', {
    type: GraphQLExceptionCode.AuthLoginWrongCredentials,
    message: intl.formatMessage({
      id: 'PASSWORD_IS_INVALID',
      defaultMessage: 'Password is incorrect',
    }),
  })
  setLoginLoading(false)
}

const genericErrorHandler = ({ intl, setError, setLoginLoading }) => {
  setError('password', {
    type: GraphQLExceptionCode.GenericError,
    message: intl.formatMessage({
      id: 'generic.error',
      defaultMessage: 'Somethign went wrong. Please contact support.',
    }),
  })
  setLoginLoading(false)
}

const notActivated = ({ intl, setError, setLoginLoading }) => {
  setError('password', {
    type: GraphQLExceptionCode.AuthUserNotActivated,
    message: intl.formatMessage({
      id: 'auth.login.user.notactivated',
      defaultMessage: 'User not activated. Please use the invite link.',
    }),
  })
  setLoginLoading(false)
}

const handlers = {
  [GraphQLExceptionCode.NotAuthorizedException]: unauthorizedHandler,
  [GraphQLExceptionCode.AuthLoginWrongCredentials]: wrongCredentialsHandler,
  [GraphQLExceptionCode.GenericError]: genericErrorHandler,
  [GraphQLExceptionCode.AuthUserNotActivated]: notActivated,
}

export const onErrorHandler = (props) => (error: ApolloError | string) => {
  graphQLErrorHandler({
    error,
    handlers,
    ...props,
  })
}

export const defaultValues = {
  email: '',
  password: '',
  rememberMe: true,
}

export const validationFields = ({
  intl,
}: ValidationInputs): ValidationFieldsProps[] => [
  validateEmail({ intl }),
  {
    name: 'password',
    validationType: 'string',
    validations: [
      {
        type: 'when',
        params: [
          'identityApplication.identityProviderCode',
          {
            is: AuthIdentityProviderCodeEnum.AwsCognito,
            then: [
              {
                type: 'min',
                params: [
                  6,
                  intl.formatMessage({
                    id: 'PASSWORD_TOO_SHORT',
                    defaultMessage: 'Password is too short',
                  }),
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'rememberMe',
    validationType: 'boolean',
  },
]
