import React from 'react'
import styled from '@emotion/styled'
import BrandLogo, { BrandLogoProps } from '@common/components/brand/logo'
import BrandCopyRight, {
  BrandCopyRightProps,
} from '@common/components/brand/copy-right'
import BrandPoweredBy from '@common/components/brand/powered-by'

interface BrandProps extends React.FC<React.HTMLAttributes<HTMLDivElement>> {
  PoweredBy: typeof BrandPoweredBy
  Logo: React.FC<React.PropsWithChildren<BrandLogoProps>>
  CopyRight: React.FC<React.PropsWithChildren<BrandCopyRightProps>>
}

const BrandWrapper = styled.div``
const Brand: BrandProps = (props) => {
  const { className = '', children, ...rest } = props
  return (
    <BrandWrapper className={className} {...rest}>
      {children}
    </BrandWrapper>
  )
}

Brand.PoweredBy = BrandPoweredBy
Brand.Logo = BrandLogo
Brand.CopyRight = BrandCopyRight

export default Brand
