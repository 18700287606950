import * as styleVars from '@common/styles/constants'
import { css } from '@emotion/react'
import { mediaQuery } from '@common/utils/breakpoints'
import styled from '@emotion/styled'

export const AuthFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 70px 40px;
  height: 100%;
  flex-direction: column;

  ${mediaQuery('medium')} {
    padding: 36px 28px 45px 28px;
  }

  ${mediaQuery('xLarge2')} {
    padding: 30px 10px;
  }

  .input-wrapper {
    ${mediaQuery('xLarge')} {
      &.pq-col {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex: 1;
      }
    }

    ${mediaQuery('medium')} {
      &.pq-col {
        width: 100%;
        flex: initial;
      }
    }

    &.pq-tel-input-mb {
      margin-bottom: 10px;

      ${mediaQuery('medium')} {
        &.pq-col {
          margin-bottom: 30px;
        }
      }
    }
  }
`

export const AuthFormHeader = styled.h2`
  font-family: Poppins;
  font-weight: bold;
  line-height: 1.33;
  text-align: center;
  color: ${styleVars.darkBlack};
`

export const AuthFormSupportingText = styled.p`
  margin-top: 6px;
  font-family: Poppins;
  text-align: center;
  color: ${styleVars.black};
  margin-bottom: 40px;
`

export const authFormSubmitBtnCss = css`
  width: 100%;
`
export const AuthFormBelow = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.29;
  text-align: center;
  margin-top: 20px;
  color: ${styleVars.darkBlack};
`
export const checkboxCss = css`
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
  color: ${styleVars.lightGrey};
`

export const AuthAccountFoundModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    text-decoration: underline;
    color: ${styleVars.primaryBlue};
  }
`

export const AuthInnerFormWrapper = styled.div`
  width: 100%;
  .login-form-wrapper {
    width: 50%;
    margin: 0 auto;

    ${mediaQuery('large')} {
      width: 65%;
    }
  }

  .pass-reset-link {
    display: inline-block;
    text-align: right;

    a {
      text-decoration: none;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 600;
      line-height: 1.5;
      letter-spacing: normal;
      color: ${styleVars.primaryBlue};
    }
  }
`
