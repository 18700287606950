import * as styleVars from '@common/styles/constants'

import BrandLogo, { BrandLogoProps } from '@common/components/brand/logo'
import { css } from '@emotion/react'
import { useIntl } from '@palqee/intl'

export type BrandPoweredByProps = BrandLogoProps

const BrandPoweredBy = (props: BrandPoweredByProps) => {
  const intl = useIntl()

  return (
    <BrandLogo
      imgSize="xs"
      imgCss={css`
        position: relative;
        width: 77px;
        height: 20px;
      `}
      textLeft={intl.formatMessage({
        id: 'powered_by',
        defaultMessage: 'Powered by',
      })}
      textLeftCss={css`
        color: ${styleVars.translucentGrey};
        font-family: Poppins;
        font-size: 10px;
      `}
      {...props}
    />
  )
}

export default BrandPoweredBy
