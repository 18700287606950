import Image, { ImageProps } from 'next/image'
import styled from '@emotion/styled'

const BrandLogoWrapper = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  & img {
    display: inline-block;
  }

  .logo-lg {
    margin: 30px 0;
    width: 160px;
    height: 40px;
  }
`

export interface BrandLogoProps extends Partial<ImageProps> {
  textLeft?: string
  textLeftCss?: any
  imgSize?: 'xs' | 'md' | 'lg' | 'xl'
  theme?: 'dark' | 'light'
  imgCss?: any
}

const BrandLogo = (props: BrandLogoProps) => {
  const {
    textLeft,
    textLeftCss,
    imgSize,
    imgCss,
    theme = 'light',
    ...imageProps
  } = props

  const renderLightTheme = (): any => {
    switch (imgSize) {
      case 'xs':
        return (
          <div css={imgCss}>
            <Image
              priority
              style={{ objectFit: 'contain' }}
              fill
              alt="Palqee Logo"
              src="/static/svg/logos/pq-logo-white.svg"
              sizes="33vw"
              {...imageProps}
            />
          </div>
        )
      case 'md':
        return (
          <div css={imgCss}>
            <Image
              priority
              style={{ objectFit: 'contain' }}
              fill
              alt="Palqee Logo"
              src="/static/images/logo/logo.png"
              sizes="33vw"
              {...imageProps}
            />
          </div>
        )
      case 'lg':
        return (
          <div css={imgCss}>
            <Image
              priority
              style={{ objectFit: 'contain' }}
              fill
              alt="Palqee Logo"
              className="logo-lg"
              src="/static/svg/logos/pq-logo-white.svg"
              sizes="33vw"
              {...imageProps}
            />
          </div>
        )
      case 'xl':
        return (
          <div css={imgCss}>
            <Image
              priority
              style={{ objectFit: 'contain' }}
              fill
              alt="Palqee Logo"
              src="/static/images/logo/logo@3x.png"
              sizes="33vw"
              {...imageProps}
            />
          </div>
        )
      default:
        return (
          <div css={imgCss}>
            <Image
              priority
              style={{ objectFit: 'contain' }}
              fill
              alt="Palqee Logo"
              className="logo-lg"
              sizes="33vw"
              src="/static/images/logo/logo@2x.png"
              {...imageProps}
            />
          </div>
        )
    }
  }

  const renderDarkTheme = (): any => {
    switch (imgSize) {
      case 'xs':
        return (
          <div css={imgCss}>
            <Image
              priority
              style={{ objectFit: 'contain' }}
              fill
              alt="Palqee Logo"
              src="/static/images/logo/logo-dark.png"
              {...imageProps}
            />
          </div>
        )
      case 'md':
        return (
          <div css={imgCss}>
            <Image
              priority
              style={{ objectFit: 'contain' }}
              fill
              alt="Palqee Logo"
              src="/static/images/logo/logo-dark.png"
              {...imageProps}
            />
          </div>
        )
      case 'lg':
        return (
          <div css={imgCss}>
            <Image
              priority
              style={{ objectFit: 'contain' }}
              fill
              alt="Palqee Logo"
              src="/static/images/logo/logo-dark@2x.png"
              {...imageProps}
            />
          </div>
        )
      case 'xl':
        return (
          <div css={imgCss}>
            <Image
              priority
              style={{ objectFit: 'contain' }}
              fill
              alt="Palqee Logo"
              src="/static/images/logo/logo-dark@3x.png"
              {...imageProps}
            />
          </div>
        )
      default:
        return (
          <div css={imgCss}>
            <Image
              priority
              style={{ objectFit: 'contain' }}
              fill
              alt="Palqee Logo"
              src="/static/images/logo/logo-dark.png"
              {...imageProps}
            />
          </div>
        )
    }
  }

  let imgLogoElem = null
  switch (theme) {
    case 'light':
      imgLogoElem = renderLightTheme()
      break
    case 'dark':
      imgLogoElem = renderDarkTheme()
      break
    default:
  }

  return (
    <BrandLogoWrapper>
      {textLeft && <span css={textLeftCss}>{textLeft}</span>} {imgLogoElem}
    </BrandLogoWrapper>
  )
}

export default BrandLogo
