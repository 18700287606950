import { ApolloError, useReactiveVar } from '@apollo/client'
import { FieldValues, Ref, UseFormSetError } from 'react-hook-form'
import {
  GraphQLExceptionCode,
  createYupSchema,
  graphQLErrorHandler,
  graphQLErrorsMakeVar,
} from '@features/core/graphql/graphql-errors'

import { IntlFormatters } from '@palqee/intl'
import { SurveyQuestion } from '@generated/types.d'
import { defineMessages } from '@features/core/react-intl/define-messages'
import { useCallback } from 'react'

type ValidationPropsParamsFunction = (value: unknown) => boolean

type ValidationPropsParamsArray = (Ref | null)[]

type ValidationPropsParamsObj = {
  is: boolean
  then: ValidationsProps[]
}

type ValidationsProps = {
  type: string
  params: (
    | string
    | number
    | ValidationPropsParamsFunction
    | ValidationPropsParamsArray
    | ValidationPropsParamsObj
  )[]
}

export type ValidationFieldsProps = {
  name: string
  validationType: string
  validations?: ValidationsProps[]
}

export type ValidationInputs = {
  intl?: IntlFormatters
  code?: string | string[]
  token?: string | string[]
  questions?: SurveyQuestion[]
  conditionalValidation?: boolean
}

export type ValidationFieldsFunction = (
  props: ValidationInputs,
) => ValidationFieldsProps[]

type FormValidationInputs = {
  validationFields: ValidationFieldsFunction
  props?: ValidationInputs
}

const minPasswordLen = 6
const capitalLetterReg = /[A-Z]/
const lowerLetterReg = /[a-z]/
const atleastOneNumReg = /[0-9]/

export const validateEmail = ({ intl }) => ({
  name: 'email',
  validationType: 'string',
  validations: [
    {
      type: 'email',
      params: [
        intl.formatMessage({
          id: 'EMAIL_INVALID',
          defaultMessage: 'Email address is invalid',
        }),
      ],
    },
    {
      type: 'required',
      params: [
        intl.formatMessage({
          id: 'EMAIL_REQUIRED',
          defaultMessage: 'Email address is required',
        }),
      ],
    },
  ],
})

export const validateNewPassword = ({ intl, fieldName }) => ({
  name: fieldName,
  validationType: 'string',
  validations: [
    {
      type: 'required',
      params: [
        intl.formatMessage({
          id: 'PASSWORD_REQUIRED',
          defaultMessage: 'Password is required',
        }),
      ],
    },
    {
      type: 'min',
      params: [
        minPasswordLen,
        intl.formatMessage({
          id: 'PASSWORD_TOO_SHORT',
          defaultMessage: 'Password is too short',
        }),
      ],
    },
    {
      type: 'test',
      params: [
        'PassRequiresCapital',
        intl.formatMessage({
          id: 'PASSWORD_REQUIRES_ONE_CAPITAL_LETTER',
          defaultMessage: 'Password requires at least one capital letter',
        }),
        (currVal) => capitalLetterReg.test(currVal),
      ],
    },
    {
      type: 'test',
      params: [
        'PassRequiresLower',
        intl.formatMessage({
          id: 'PASSWORD_REQUIRES_ONE_LOWER_LETTER',
          defaultMessage: 'Password requires at least one lowercase letter ',
        }),
        (currVal) => lowerLetterReg.test(currVal),
      ],
    },
    {
      type: 'test',
      params: [
        'PassRequiresNumber',
        intl.formatMessage({
          id: 'PASSWORD_REQUIRES_ONE_NUMBER',
          defaultMessage: 'Password requires at least one number',
        }),
        (currVal) => atleastOneNumReg.test(currVal),
      ],
    },
  ],
})

export const validateExistingPassword = ({ intl, fieldName }) => ({
  name: fieldName,
  validationType: 'string',
  validations: [
    {
      type: 'required',
      params: [
        intl.formatMessage({
          id: 'PASSWORD_REQUIRED',
          defaultMessage: 'Password is required',
        }),
      ],
    },
    {
      type: 'test',
      params: [
        'tempPasswordLen',
        intl.formatMessage({
          id: 'TEMPORARY_PASS_SHOULD_HAVE_LENGTH',
          defaultMessage: 'Temporary password must be 10 characters',
        }),
        (val) => {
          return val.length === 10
        },
      ],
    },
    {
      type: 'test',
      params: [
        'tempPasswordAlpha',
        intl.formatMessage({
          id: 'TEMPORARY_PASS_SHOULD_BE_ALPHANUMERIC',
          defaultMessage:
            'Temporary password should not contain special characters',
        }),
        (val) => {
          const isInvalid = /[^A-Za-z0-9]/g.test(val)
          return !isInvalid
        },
      ],
    },
    {
      type: 'min',
      params: [
        6,
        intl.formatMessage({
          id: 'PASSWORD_TOO_SHORT',
          defaultMessage: 'Password is too short',
        }),
      ],
    },
  ],
})

/**
 * @todo dont think this works well with array / object types
 *       fix !!!
 * @param param0
 * @returns
 */
export const useFormValidation = ({
  validationFields,
  props,
}: FormValidationInputs) => {
  const graphQLErrors = useReactiveVar(graphQLErrorsMakeVar)
  const fields = validationFields(props)

  const { intl } = props

  const yupSchema = createYupSchema({
    fields,
    graphQLErrors,
    intl,
  })

  const triggerCallback = useCallback(
    async (trigger) => {
      if (graphQLErrors?.length > 0) {
        const triggers = graphQLErrors.map((error) => error?.name)

        await trigger(triggers)
      }
    },
    [graphQLErrors],
  )

  return {
    yupSchema,
    triggerCallback,
  }
}

const phoneNumberErrorHandler = ({ intl, setError }) => {
  setError('phoneNumber', {
    type: GraphQLExceptionCode.AuthSignUpPhoneNumberAlreadyUsed,
    message: intl.formatMessage(
      defineMessages[GraphQLExceptionCode.AuthSignUpPhoneNumberAlreadyUsed],
    ),
  })
}

// @todo update to handle case for various field names
const invalidParametersHandler = ({ intl, setError }) => {
  setError('phoneNumber', {
    type: 'PHONE_NOT_VALID',
    message: intl.formatMessage({
      id: 'PHONE_NOT_VALID',
      defaultMessage: 'Phone number is not valid',
    }),
  })
}

const notAuthorizedExceptionHandler = ({ intl, setError }) => {
  setError('password', {
    type: GraphQLExceptionCode.NotAuthorizedException,
    messsage: intl.formatMessage(
      defineMessages[GraphQLExceptionCode.NotAuthorizedException],
    ),
  })
}

const wrongCredentialsHandler = ({ intl, setError, field }) => {
  setError(field ? field : 'password', {
    type: GraphQLExceptionCode.AuthLoginWrongCredentials,
    message: intl.formatMessage(
      defineMessages[GraphQLExceptionCode.AuthLoginWrongCredentials],
    ),
  })
}

const authNotAuthenticatedHandler = ({ intl, setError }) => {
  setError('email', {
    type: GraphQLExceptionCode.AuthNotAuthenticated,
    message: intl.formatMessage({
      id: 'EMAIL_IS_INVALID',
      defaultMessage: 'E-mail address is incorrect',
    }),
  })
}

const signupEmailAlreadyUsedHandler = ({ intl, setError }) => {
  setError('email', {
    type: GraphQLExceptionCode.AuthSignUpEmailAlreadyUsed,
    message: intl.formatMessage(
      defineMessages[GraphQLExceptionCode.AuthSignUpEmailAlreadyUsed],
    ),
  })
}

export const genericErrorHandler = ({
  intl,
  setError,
  errorCode,
  name,
}: {
  intl: IntlFormatters
  setError: UseFormSetError<FieldValues>
  errorCode: `${GraphQLExceptionCode}`
  name: string
}) => {
  setError(name, {
    type: errorCode,
    message: intl.formatMessage(defineMessages[errorCode]),
  })
}

const handlers = {
  [GraphQLExceptionCode.AuthSignUpPhoneNumberAlreadyUsed]:
    phoneNumberErrorHandler,
  [GraphQLExceptionCode.InvalidParameterException]: invalidParametersHandler,
  [GraphQLExceptionCode.NotAuthorizedException]: notAuthorizedExceptionHandler,
  [GraphQLExceptionCode.AuthLoginWrongCredentials]: wrongCredentialsHandler,
  [GraphQLExceptionCode.AuthNotAuthenticated]: authNotAuthenticatedHandler,
  [GraphQLExceptionCode.ApiInputValidationFailed]: invalidParametersHandler,
  [GraphQLExceptionCode.AuthSignUpEmailAlreadyUsed]:
    signupEmailAlreadyUsedHandler,
}

/**
 * Attempt to setup a global graphql error handler
 *
 * @param props props to be used in handlers
 * @param error Apollo error
 * @returns void
 */
export const onErrorHandler =
  (props) =>
  (error: ApolloError): void => {
    graphQLErrorHandler({
      error,
      handlers,
      ...props,
    })
  }
