// src
import * as styleVars from '@common/styles/constants'

import { FormattedMessage, useIntl } from '@palqee/intl'
import React, { PropsWithChildren } from 'react'

import Link from 'next/link'
import styled from '@emotion/styled'

const BrandCopyRightWrapper = styled.div`
  p.bottom-legal-text {
    margin-top: 15px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.5;
    color: ${styleVars.lightGrey};
    display: flex;
    justify-content: end;

    button {
      font-size: 12px !important;
      font-weight: 600 !important;
      line-height: 1.5 !important;
    }
  }
`

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BrandCopyRightProps {}

const BrandCopyRight: React.FC<
  React.PropsWithChildren<PropsWithChildren<BrandCopyRightProps>>
> = () => {
  const intl = useIntl()
  return (
    <BrandCopyRightWrapper>
      <p className="bottom-legal-text">
        <>&copy;2019 - {new Date().getFullYear()} Palqee Technologies Ltd.</>
        <>&nbsp;&nbsp;</>
        <FormattedMessage
          id="ALL_RIGHTS_RESERVED"
          defaultMessage="All Rights Reserved. {link}"
          values={{
            link: (
              <Link
                target="_blank"
                rel="noreferrer"
                className="pq-text-color-primary-blue"
                href={intl.formatMessage({
                  id: 'PRIVACY_POLICY_NAV_LINK',
                  defaultMessage: 'https://www.palqee.com/privacy-policy',
                })}
              >
                <>&nbsp;&nbsp;</>
                {intl.formatMessage({
                  id: 'PRIVACY_POLICY',
                  defaultMessage: 'Privacy Policy',
                })}
              </Link>
            ),
          }}
        />
      </p>
    </BrandCopyRightWrapper>
  )
}

export default BrandCopyRight
